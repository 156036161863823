$text_color: #000;
$font_family : 'Lucida Sans',
'Lucida Sans Regular',
'Lucida Grande',
'Lucida Sans Unicode',
Geneva,
Verdana,
sans-serif;

#navLink {
    // width: 100%;
    height: 100vh;
    // position: fixed;
    // overflow: hidden;
    padding: 20px 0;


    a {
        text-decoration: none;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu {
        height: 80%;
        width: 100%;
        margin: 0;
        padding-left: 10px;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        font-size: 16;
        font-weight: 500;
        font-family: 'serif';
        transition: 0.1s;



        .nav-active {
            opacity: 0.9;
            font-size: 19px;
            background-color: rgba(52, 52, 226, 0.487);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                height: 0px;
                background: rgb(52, 52, 226);
                width: 4px;
                top: 50%;
                border-radius: 50px;
                animation: nav-animation 0.8s forwards;
            }

            @keyframes nav-animation {
                to {
                    transform: translateY(-50%);
                    height: 100%;
                }
            }
        }
    }

    // @font-face {
    //     font-family: 'Lato';
    //     src: url(/src/assiets/fonts/Lato-Regular.ttf)format('truetype');
    // }

    li {
        display: inline-block;
        color: $text_color;
        opacity: 0.6;
        font-family: $font_family;
        transition: 1ms;
        font-size: 18px;

        &:hover {
            opacity: 0.8;
        }

        .submenu {
            font-size: 20px;
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .logout {
        bottom: 0;
        width: 17%;
        height: 20vh;
        display: flex;
        justify-content: start;
        align-items: center;
        position: absolute;
        margin-left: 20px;

        button {
            color: $text_color;
            font-family: $font_family;
            font-size: 18px;
            font-weight: 500;
            opacity: 0.6;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: transparent;

            &:hover {
                opacity: 0.8;
                font-size: 19px;
            }
        }

    }
}

.navigation {
    width: 15vw;
    height: 100vh;
    background-color: white;
    box-sizing: border-box;
}