@import './login.scss';
@import './sideBar.scss';
// @import './homePage.scss';
@import './loading.scss';
@import './circleLoading.scss';
@import './404simple.scss';
@import './statPopUp.scss';

* {
    margin: 0;
    padding: 0;
    max-height: 100vh;
    box-sizing: border-box;
}

body {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    overflow: hidden;
}

// .row {
//     display: flex;
//     flex-direction: row;
// }